import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from "../../../layouts/Default"
import NonStretchedImage from "../../common/NonStretchedImage"
import Nav from "../common/Nav"
import TextBlock from "../common/TextBlock"
import TopSection from "../common/TopSection"
import styles from './styles.module.scss';

const imagesQuery = graphql`
     query {
    image1: file(relativePath: { eq: "cases/venduai/1.webp" }) {
      ...fluidImage
    }
    image2: file(relativePath: { eq: "cases/venduai/2.webp" }) {
      ...fluidImage
    }
    image3: file(relativePath: { eq: "cases/venduai/3.webp" }) {
      ...fluidImage
    }
    image4: file(relativePath: { eq: "cases/venduai/4.webp" }) {
      ...fluidImage
    }
    logo1: file(relativePath: { eq: "cases/venduai/logo/1.webp" }) {
      ...fluidImage
    }
    logo2: file(relativePath: { eq: "cases/venduai/logo/2.webp" }) {
      ...fluidImage
    }
  }
  `;

const Vendu = (): JSX.Element => {
  const data = useStaticQuery(imagesQuery);
  const deliveries = [
    'UX og tjenestedesign',
    'Grafisk design',
    'Kunstig intelligens og maskinlæring',
    'Front - og back end utvikling',
    'Produktutvikling',
    'Lean Startup',
    'Styremedlem'];

  return (
    <Layout darkMode={true} className={styles.container}>
      <TopSection project={"Utvikling av Vendu sine digitale tjenester og identitet."} deliveries={deliveries}>
        <p>
          <b>Vendu AI - vår egen startup.</b> Et eiendomsteknologiselskap (aka Proptech).
          Ved hjelp av maskinlæring og store mengder data om norske boliger utvikler de en boligplattform med verdens første boligintelligens.
        </p>
      </TopSection>
      <NonStretchedImage {...data.image1.childImageSharp} />
      <NonStretchedImage className={styles.smallImageWrapper} {...data.logo1.childImageSharp} />
      <NonStretchedImage {...data.image2.childImageSharp}/>
      <TextBlock header={"Mer enn et prosjekt"} bottom={true}>
        <p>
          Vendu AI er mer enn et prosjekt for Uppercase. Det er en plattform,
          et økosystem og en misjon om å gjøre både handel og håndtering av bolig tryggere og enklere for alle i Norge og Norden.
        </p>
        <p>
          Gjennom et forskningsprosjekt som Vendu leder, støttet av Norges Forskningsråd, har man fått tilgang på et helt unikt datasett.
          Mer om forskningsprosjektet: http://vendu.ai/bia
        </p>
        <p>
          Den første suksessfulle tjenesten og forretningsmodellen som er ute heter Smart Boliganalyse.
          Målet med tjenesten Smart Boliganalyse er å gjøre informasjon om teknisk tilstand for en bolig lettere tilgjengelig og lettere forståelig for alle på boligjakt.
          Første kunde er forsikringsselskapet Søderberg & Partners som betaler for distribusjonen av boliganalyser i samarbeid med eiendomsmeglere over hele Norge.
          Lenke til tjenesten: boliganalyse.vendu.no
        </p>
      </TextBlock>


      <NonStretchedImage {...data.image3.childImageSharp}/>
      <TextBlock wide={true}>
        <p>
          Cras mattis consectetur purus sit amet fermentum.
          Morbi leo risus, porta ac const tur ac, vestibulum at eros. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
      </TextBlock>
      <NonStretchedImage className={styles.smallImageWrapper} {...data.logo2.childImageSharp} />
      <NonStretchedImage {...data.image4.childImageSharp} />
      <Nav/>
    </Layout>
  )
};

export default Vendu;

