import React from 'react';
import Vendu from '../components/cases/Vendu';

const Page = (): JSX.Element => (
  <>
    <Vendu />
  </>
);

export default Page;
